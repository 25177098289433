body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.secondary.pointing.menu a:first-child {
  margin-left: auto;
}

.ui.secondary.pointing.menu a:last-child {
  margin-right: auto;
}

.headshot {
  max-width: 250px;
  max-height: 250px;

}

.execCard {
  height: 560px;
}

.dynamicRow {
  display: flex;
  justify-content: center;
}

.dynamicEl {
  padding: 1rem;
}

.groupPic {
  height: 500px;
  margin-left: auto;
  margin-right: auto;
}

List.Item {
  height: 1000px;
}

.constPopup {
  width: 1000px;
  height: 300px;
  overflow: auto;
}

.home {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50%;
  width : 50%;
  background-color:  rgb(245,245,245);
}

.article {
  height: 50px;
}

.footer {
  padding-top: 3%;
  background-color:  rgb(245,245,245);
  height: 150px;
  text-align: center;
 
}

.head {
  padding-top: 7%;
  padding-left: 5%;
  text-align: center;
  height:250px;
  background-image: url("./data/images/equad.jpeg");
}

.headerText{
  
  display: inline-block;
  -webkit-text-stroke: white;
  -webkit-text-stroke-width: 1px;
  font-size: larger;
}

.tabContent{
  padding-top: 1%;
  padding-left: 1%;
  padding-right: 0%;
  height: 100%;
  background-color: rgb(245,245,245);
}

.homeTab {
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 3%;
  height: 100%;
  background-color: rgb(245,245,245);
}

.center {
  margin:auto;
  text-align: center;
}

* {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: #1e2c3a;
  font-size: 1rem;
}

.animationText {
  text-align: left;
  color: 	rgb(245,245,245);
  padding-top: 5%;
  padding-left: 3%;
  
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.vantaAnimation {
  padding-bottom: 7%;
}
.row {
  width: 100%;
  height: 100%;
  
}

.externalLink{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: #1e2c3a;
}

.externalLink:hover{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: purple;
}

.leftCentered {
  text-align: left;
}

.centerCentered {
  text-align: center;
  
  margin-left: auto;
  margin-right: auto;
}

.headerThree {
  font-size: 22px;
}

.overallGrid {
  background-color: rgb(245,245,245);
}

.sideBar {
  width: 20%;
  padding-left: 2rem;
}

.sideBarShrunk {
  padding: 1rem;
}

.sideBarContent{
  width: 80%;
  padding-right: 4rem;
}

.sideBarContentShrunk{
  padding-left: 1rem;
  padding-right: 1rem;
}

.navBar {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0rem;
  background-color: 	rgb(245,245,245);
  width: 100%;
  height: 5%;
}

.navMenuShrunk {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navMenu {
  display: flex;
  justify-content: center;
}

.verticalAlign {
    display: flex;
    align-items: center;
    justify-content: center;

}

.halfContent {
  width: 50%;
  padding-left: 1rem;
}

.halfContentShrunk {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
}

.gridColumnRow{
  padding-left: 1rem;
  padding-right: 1rem;
}


